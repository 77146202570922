<template>
<AppLayout>
    <template v-slot:appContent>
        <div>
            <section class="card">
                <div class="card-body px-md-4">
                    <div class="row">
                        <div class="col-md-3">
                            <h6 class="text-secondary mb-1" style="font-weight: 500;">Customer Details</h6>
                            <div class="" v-if="!isEmptyObject(user.address)">
                                <p style="font-weight: 600;margin-bottom: 0; text-transform: capitalize">
                                    Name:
                                    {{ `${user.firstName} ${user.lastName}` }} </p>
                                <p style="margin-bottom: 0;font-weight: 500;" v-if="user?.address">
                                    Address:
                                    {{
                      `${user.address.street}, ${user.address.suburb}, ${user.address.state.toUpperCase()}, ${user.address.post_code}, ${user.address.country}`
                    }}</p>
                                <p style="margin-bottom: 0;font-weight: 500;">
                                    Phone: {{ user?.phoneNumber }}</p>
                                <p style="font-weight: 500; margin-bottom: 20px;">Email: {{ user?.email }}</p>
                            </div>

                        </div>
                        <div class="col-md-3 mt-2 mt-md-0">
                            <h6 class="text-secondary mb-1" style="font-weight: 500;">Order Details</h6>
                            <div v-if="!isEmptyObject(orderInfo)">
                                <p style="margin-bottom: 0;font-weight: 500;">Order No: {{ orderReference }} </p>
                                <p style="margin-bottom: 0;font-weight: 500;">Order Date: {{ orderDate }}</p>
                                <p style="margin-bottom: 0;font-weight: 500;">Delivery Date: {{ deliveryDate }}</p>
                                <p style="margin-bottom: 0;">Payment Methods:
                                    <span v-for="(value, key, index) in paymentMethods" :key="index">
                                        <span>{{ key }}</span>
                                        <span class="badge badge-pill badge-primary badge-round">{{ value }}</span>
                                    </span>
                                </p>
                                <p style="margin-bottom: 0;font-weight: 500;">Order Status: {{ orderStatus }}</p>
                            </div>
                        </div>

                        <div class="col-md-3 mt-2 mt-md-0 d-flex ">
                            <div class="">

                                <h6 class="text-secondary mb-1" style="font-weight: 500;">Billing Address</h6>
                                <div v-if="!isEmptyObject(user.address)">
                                    <p style="margin-bottom: 0;font-weight: 500; text-transform: capitalize">Name :
                                        {{ `${user.firstName} ${user.lastName}` }} </p>
                                    <p style="margin-bottom: 0;font-weight: 500;">Email: {{ user?.email }}</p>
                                    <p style="margin-bottom: 0;font-weight: 500;">Phone: {{ user?.phoneNumber }}</p>
                                    <p style="margin-bottom: 0;font-weight: 500;" v-if="user.address !== undefined">Address:
                                        {{ user.address.street }},
                                        {{ user.address.suburb }},
                                        {{ user.address.state.toUpperCase() }}
                                        {{ user.address.post_code }},
                                        {{ user.address.country }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mt-2 mt-md-0 d-flex ">
                            <div class="">
                                <h6 class="text-secondary mb-1" style="font-weight: 500;">Shipping Address</h6>
                                <div v-if="!isEmptyObject(orderInfo)">
                                    <p style="margin-bottom: 0;font-weight: 500; text-transform: capitalize">Name :
                                        {{ orderInfo.shipping_address.name }} </p>
                                    <p style="margin-bottom: 0;font-weight: 500;">Email:
                                        {{ orderInfo?.shipping_address?.email ?? 'Not provided' }}</p>
                                    <p style="margin-bottom: 0;font-weight: 500;">Phone:
                                        {{ orderInfo?.shipping_address?.phone ?? 'Not provided' }}</p>
                                    <p style="margin-bottom: 0;font-weight: 500;">Address:
                                        {{ orderInfo.shipping_address.address.street }},
                                        {{ orderInfo.shipping_address.address.suburb }},
                                        {{ orderInfo.shipping_address.address.state.toUpperCase() }},
                                        {{ orderInfo.shipping_address.address.postCode }},
                                        {{ orderInfo.shipping_address.address.country }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <!--              <div class="btn btn-light-warning btn-sm" @click="showProductList">
                              <i class='bx bx-plus font-weight-bold'>Add</i>
                            </div>-->
                    </div>
                    <!-- PRODUCT DETAILS SECTION -->
                    <div class="d-none d-md-block">
                        <div class="row pb-1">
                            <div class="col-md-5 text-bold-600">PRODUCT</div>
                            <div class="col-md-2 text-center text-bold-600">QUANTITY</div>
                            <div class="col-md-2 text-center text-bold-600">PRICE</div>
                            <div class="col-md-2 text-right text-bold-600">TOTAL</div>
                            <div class="col-md-1 text-right text-bold-600"></div>
                        </div>
                        <!--            start  cart list section-->
                        <div class="row" v-for="(cartItem, index) in details.cart_data" :key="index">
                            <div class="col-md-5">
                                <div class="d-flex">
                                    <div class="d-inline-block mr-1 flex-shrink-0">
                                        <img src="https://app.geekscrs.com.au/uploads/products/r0AZcwSL07_3.jpg" width="80" height="80" alt="">
                                    </div>
                                    <div class="d-inline-block align-top">
                                        <h4 class="mb-0 text-capitalize">{{ cartItem.name }}({{ cartItem.product_price.condition_name }})
                                            <!-- <button type="button" class="btn btn-sm px-0 py-0"
                                style="padding:0;"
                                @click="getSerial(cartItem.productId,cartItem.name, cartItem.product_price.conditionType,cartItem.product_price.quantity, cartItem.product_price.id)"
                                data-toggle="modal" data-target="#addProductSerialModal">
                          <i class='bx bx-folder-plus bx-flashing text-danger'></i></button> -->

                                        </h4>
                                        <p class="small text-secondary mb-0">{{ cartItem.description }}</p>
                                        <div v-if="cartItem?.product_items?.length > 0">
                                            <ul>
                                                <li v-for="(item,productItemIndex) in cartItem.product_items" :key="productItemIndex">
                                                    <span v-if="item.is_admin_process">{{ item?.item_obj?.serial_number ?? 'Not provided'  }}</span>

                                                    <button v-else @click="openAddProcureProductDetailsModal(index, productItemIndex)" class="btn btn-sm px-0 pr-0 py-0" :class="item?.procureProduct?.productItemObj?.serial_number ? 'text-success' : 'text-warning'" :id="`item-${index}-${productItemIndex}`">
                                                        {{ item?.procureProduct?.productItemObj?.serial_number ? 'Update Details' : 'Add Details' }}
                                                        <i :class="item?.procureProduct?.productItemObj?.serial_number ? 'bx bx-check-circle' : 'bx bxs-info-circle'"></i>
                                                    </button>
                                                </li>

                                            </ul>
                                        </div>
                                        <!--                      <div v-if="!cartItem.adminProcessed">
                                              <ul>
                                                <li v-for="(single,index) in cartItem.product_price.quantity" :key="single" :id="`item-${cartItem.product_price.id}-${index}`">
                                                  <button @click="openAddProcureProductDetailsModal(cartItem.product_price.id,index)"
                                                          class="btn btn-sm px-0 pr-0 py-0 text-warning">Add Details<i
                                                      class='bx bxs-info-circle'></i></button>
                                                </li>
                                              </ul>
                                            </div>-->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 d-flex justify-content-center">
                                <div class="d-inline-block">
                                    <div class="d-flex align-items-center quantity-section justify-content-start">
                                        <div class="btn-quantity" @click="decreaseQuantity(index)">
                                            <i class='bx bx-minus font-weight-bold'></i>
                                        </div>
                                        <div class="quantity">
                                            {{ cartItem.product_price.quantity }}
                                        </div>
                                        <div class="btn-quantity" @click="increaseQuantity(index)">
                                            <i class='bx bx-plus font-weight-bold'></i>
                                        </div>
                                    </div>
                                </div>
                                <!--                  <div v-else>{{ cartItem.product_price.quantity }}</div>-->

                            </div>
                            <div class="col-md-2 text-center">${{ parseFloat(cartItem.product_price.amount / 100).toFixed(2) }}</div>
                            <div class="col-md-2 text-right">${{
                    parseFloat(cartItem.product_price.sub_total / 100).toFixed(2)
                  }}
                            </div>
                            <div class="col-md-1 text-right">
                                <a type="button" @click="removeCartItem(index,cartItem.product_price.id)" class="badge-light-danger badge-pill badge-round" role="button" :aria-disabled="details.cart_data && details.cart_data.length <=1">
                                    <i class='bx bx-x'></i>
                                </a>
                            </div>
                        </div>
                        <!--            end cart list section-->
                    </div>
                    <hr>
                    <div class="row d-flex justify-content-lg-between justify-content-around">
                        <div class="col-lg-7 row">
                            <div class="col-12" v-if="orderNote.id !== null">
                                <div class="form-group">
                                    <label>Notes</label>
                                    <QuillEditor ref="notes" v-model:content="description" contentType="text" toolbar="essential" theme="snow" />
                                </div>
                            </div>
                            <div class="" v-if="!isEmptyObject(orderInfo) && orderInfo?.creator?.panel === 3">
                                <div class="form-group">
                                    <label class="text-uppercase brand-color">Preferred Delivery Date</label>
                                    <div class="d-flex align-items-center date-picker-wrapper">
                                        <div class="w-100 ">
                                            <DatePicker v-model="delivery_date" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :min-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                                <template v-slot="{ inputValue ,togglePopover  }">
                                                    <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                                                        <i class='bx bx-calendar mr-1'></i>
                                                        <input class="form-control" :value="inputValue" style="cursor: pointer;" />
                                                    </div>
                                                </template>
                                            </DatePicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-3">
                            <table class="table table-borderless">

                                <tbody v-if="!isEmptyObject(orderInfo)">
                                    <tr>
                                        <td class="p-0">SubTotal</td>
                                        <td class="px-0 text-right" style="padding:4px 0;">
                                            ${{ parseFloat(orderInfo.subtotal_amount / 100).toFixed(2) }}
                                        </td>
                                    </tr>
                                    <tr v-if="orderInfo.order_delivery_charge.applied_status">
                                        <td class="p-0">Delivery Charge (+)</td>
                                        <td class="px-0 text-right" style="padding:4px 0;">
                                            ${{ parseFloat(orderInfo.order_delivery_charge.amount / 100).toFixed(2) }}
                                        </td>
                                    </tr>
                                    <tr v-if="orderInfo.gst_charge.applied_status">
                                        <td class="p-0">GST (+)</td>
                                        <td class="px-0 text-right" style="padding:4px 0;">
                                            ${{ parseFloat(orderInfo.gst_charge.amount / 100).toFixed(2) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="p-0">
                                            <hr class="" style="margin: 2px 0">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">Discount (-)</td>
                                        <td class="px-0 text-right" style="padding:4px 0;">
                                            ${{ parseFloat(orderInfo.total_discount_amount / 100).toFixed(2) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">Grand Total</td>
                                        <td class="px-0 text-right" style="padding:4px 0;">
                                            ${{ parseFloat(orderInfo.grand_total / 100).toFixed(2) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">Paid Amount</td>
                                        <td class="px-0 text-right" style="padding:4px 0;">
                                            ${{ parseFloat(paymentInfo.paid_amount / 100).toFixed(2) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">Remaining Amount</td>
                                        <td class="px-0 text-right" style="padding:4px 0;">
                                            ${{ parseFloat((orderInfo.grand_total - paymentInfo.paid_amount) / 100).toFixed(2) }}
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div>

                    </div>
                    <div class="row mb-5" v-show="orderStatus ==='Pending'">
                        <div class="col-12 ">
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-primary update-todo px-4" @click="orderProcess()">Order Process
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AddProductSerialModal :product-items="productItems" :cart-item-quantity="cartItemCount" :added-items="productItemsID" :selected-product="selectedProduct" :selected-state="selectedState" :selected-condition="selectedCondition" @getSelectedItemId="selectedItemId" />

            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
            <ProductListModal :products="products" @selectedProductCondition="getEmitData($event)" />

            <AddProcureProductItemModal :vendor-list="[...vendors]" :procure-product="selectedProcureProduct" :parent-index="selectedProductIndex" :child-index="selectedProductItemIndex" :is-Franchisee-data-send="processStatus" :product-data="selectedProductObj" :franchisee-id="orderFranchiseeUserId" @productItemObj="onProcureProductDetails" />
            <div class="" data-toggle="modal" data-target="#showOrderNo"></div>
            <div class="" data-toggle="modal" data-target="#productListModal"></div>

            <div class="" data-toggle="modal" data-target="#addProcureProductItemModal"></div>
        </div>
    </template>
</AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import {
    mapActions,
    mapGetters,
} from 'vuex';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import AddProductSerialModal from "@/views/backEnd/Orders/includes/AddProductSerialModal";

import {
    QuillEditor
} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {
    DatePicker
} from "v-calendar";
import ProductListModal from "@/views/backEnd/Orders/includes/ProductListModal";
import ConfirmDialogue from "@/components/backEnd/modal/ConfirmDialogue"
import AddProcureProductItemModal from "@/views/backEnd/Orders/AddProcureProductItemModal";

export default {
    components: {
        AppLayout,
        AddProductSerialModal,

        AddProcureProductItemModal,
        QuillEditor,
        DatePicker,
        ProductListModal,
        ConfirmDialogue
    },
    mixins: [ShowToastMessage, Loader],
    name: "OrderProcure",
    data() {
        return {
            selectedProductItemId: '',
            delivery_date: '',
            modalOpen: false,
            preOrderChargesKey: [
                'order_delivery_charge',
                'applied_discount',
                'coupon_discount',
                'credited_payment_discount',
                'gst_charge'
            ],
            getOrderParams: {
                with_relation: [
                    'user',
                    'user.customer.address.business',
                    'orderNotes',
                    'orderProductItems.productItem',
                    'orderCreator.user.franchisee',
                    'orderCreator.user.technician.franchiseeTechnician.franchisee',
                    'appointment.technicianAppointment.technician.franchiseeTechnician.franchisee.user'
                ]
            },
            user: {
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                dateOfBirth: '',
                avatar: '',
                address: {}
            },
            details: {},
            paymentReference: '',
            paymentInfo: {
                paid_amount: ''
            },
            orderReference: '',
            deliveryDate: '',
            orderDate: '',
            paymentMethods: '',
            orderStatus: '',
            selectedListId: '',
            cartItemCount: '',
            orderID: '',
            paymentID: '',
            productItemsID: {},
            paymentType: '',
            getPaymentParams: {
                with_relation: ['cardPayment', 'cashPayment', 'eftPayment'],
                reference: ''
            },
            cardPayment: {
                id: '',
                gatewayID: '',
                status: ''
            },
            cashPayment: {
                id: '',
                payment_id: '',
                paid_by: '',
                paid_to: '',
                handover_date: '',
            },
            eftPayment: {
                id: ''
            },
            getSettingsParams: {
                type: ['default', 'product_item'],
                key: ['default_gst_rate', 'product_item_condition'],
            },
            gstRate: '',
            selectedProduct: {
                id: '',
                name: ''
            },
            selectedState: '',
            selectedCondition: '',
            orderCreatedAt: '',
            paymentMethodType: '',
            addedProductItems: [],
            addedProductItemPrices: [],
            addedProductItemLicense: [],
            selectedProductPriceId: '',
            description: '',
            cardPaymentArray: [],
            orderInfo: {},
            getProductsParams: {
                with_relation: ['addedBy', 'productCategory', 'productItems'],
                //with_product_prices_state: '',
                // with_product_prices_status: 1,
                where_has_product_price_state_and_status: []

            },
            products: {},
            orderNote: {
                id: '',
                description: ''
            },
            orderPaymentArray: [],
            alreadyPaidAmount: 0,
            productLicenseData: [],
            getVendorsParams: {
                status: 1,
                with_relation: ['user'],
                order_by_id: 'DESC',

            },
            selectedProductIndex: '',
            selectedProductItemIndex: '',
            selectedProcureProduct: {},
            selectedProductObj: {}

        }
    },
    computed: {
        ...mapGetters({
            previousRoute: 'previousRoute',

            authUser: 'appAuthLogin/authUser',
            authFranchisee: 'appAuthLogin/authFranchisee',
            order: 'appOrders/order',
            vendors: 'appVendors/vendors',

            productItems: 'appProductItems/productItems',
            payments: 'appPayments/payments',

        }),
        conditionOptions() {
            return this.$store.getters['appSettings/settingProductItemCondition'].value

        },
        isOrderNoteChange() {
            return !(this.orderNote.description === this.description.slice(0, -1));
        },
        processStatus() {
            return this.currentFranchiseeId ? !(this.orderInfo ?.isOrderAdminProcessed ?? false) : this.orderInfo ?.isFranchiseeProcess ?? false;
        },
        currentFranchiseeId() {
            return this.authFranchisee.id
        },
        orderFranchiseeUserId() {
            let franchiseeOrder = this.order ?.orderCreator ?.user ?.franchisee ?.status === 'Active' ?
                this.order ?.orderCreator ?.user ?.id : null;
            let franchiseeTechOrder = this.order ?.orderCreator ?.technician ?.franchiseeTechnician ?.franchisee ?.status === 'Active' ?
                this.order ?.orderCreator ?.user ?.id : null;
            let orderTagAppointmentTech = this.order ?.appointment ?.technicianAppointment ?.technician ?.franchiseeTechnician ?.franchisee ?.user ?.id ?? null;

            return franchiseeOrder || franchiseeTechOrder || orderTagAppointmentTech;
        }
    },

    methods: {
        ...mapActions({
            getVendors: 'appVendors/getVendors',
            getSettings: 'appSettings/getSettings',
            getOrder: 'appOrders/getOrder',
            getProductItems: 'appProductItems/getProductItems',
            getPayments: 'appPayments/getPayments',
            getProducts: 'appProducts/getProducts',

            paymentCapture: 'appPaymentGateways/paymentCapture',
            postAuthorizationReversed: 'appPaymentGateways/authorizationReversed',

            postOrderCreator: 'appOrderCreators/postOrderCreator',
            postOrderPayment: 'appOrderPayments/postOrderPayment',
            postOrderChargeStoreList: 'appOrderCharges/postOrderChargeStoreList',
            postOrderProductItemStoreList: 'appOrderProductItems/postOrderProductItemStoreList',
            postProductItemTrackingStoreList: 'appProductItemTracking/postProductItemTrackingStoreList',
            postProductItemTransactionStoreList: 'appProductItemTransaction/postProductItemTransactionStoreList',
            postProductLicenseUpdateList: 'appProductLicenses/postProductLicenseUpdateList',
            postRefund: 'appRefunds/postRefund',
            postRefundNote: 'appRefundNotes/postRefundNote',

            putCardPayment: 'appCardPayments/putCardPayment',
            putCashPayment: 'appCashPayments/putCashPayment',
            putEftPayment: 'appEftPayments/putEftPayment',
            putOrder: 'appOrders/putOrder',
            putOrderNote: 'appOrderNotes/putOrderNote',

            resetOrder: 'appOrders/resetOrder',
        }),

        async openAddProcureProductDetailsModal(index, itemIndex) {
            console.log(index, itemIndex)
            // initial reset obj for watch proper change
            this.selectedProcureProduct = {};
            this.selectedProductObj = {};

            this.selectedProductIndex = index;
            this.selectedProductItemIndex = itemIndex;

            let selectedProduct = this.details.cart_data[index];
            this.selectedProductObj = {
                product: {
                    id: selectedProduct.product_id,
                    name: selectedProduct.name
                },
                condition: {
                    id: selectedProduct.product_price.conditionType,
                    name: selectedProduct.product_price.condition_name
                },
                state: this.user.address.state
            };
            this.selectedProcureProduct = await this.details.cart_data[index].product_items[itemIndex].procureProduct;
            document.querySelector('[data-target="#addProcureProductItemModal"]').click();
        },

        onProcureProductDetails(data) {
            document.querySelector('[data-target="#addProcureProductItemModal"]').click();
            // procureObj data property change by status
            let {
                parentIndex,
                childIndex,
                procureObj,
                status
            } = data;
            if (status === 1) {
                // procureObj data is current input field data
                console.log(procureObj, "procureObj status 1");
                this.details.cart_data[parentIndex].product_items[childIndex].procureProduct = procureObj;

                let productItem = {
                    // id: item.id,
                    // serial_number: item.serial_number,
                    // productLicense: item.productLicense,
                    // isAdminProcessed: true,
                    // is_admin_process: true,

                    // orginal 
                    is_procure_item: true,
                    is_admin_process: false,
                    item_obj: {
                        purchaser: this.authUser.id,
                        vendor_id: procureObj ?.productItemObj ?.vendor_id ?? '',
                        warehouse_id: procureObj ?.productItemObj ?.warehouse_id ?? '',
                        product_id: procureObj ?.productItemObj ?.product_id ?? '',
                        serial_number: procureObj ?.productItemObj ?.serial_number ?? '',
                        model_number: procureObj ?.productItemObj ?.model_number ?? '',
                        sku: procureObj ?.productItemObj ?.sku ?? '',
                        barcode: procureObj ?.productItemObj ?.barcode ?? '',
                        qr_code: procureObj ?.productItemObj ?.qr_code ?? '',
                        manufacturing_code: procureObj ?.productItemObj ?.manufacturing_code ?? '',
                        purchase_cost: procureObj ?.productItemObj ?.purchase_cost ?? '',
                        condition: procureObj ?.productItemObj ?.condition ?? '',
                        state: procureObj ?.productItemObj ?.state ?? '',

                    },
                    item_image_obj: procureObj.productImageObj.map((item) => ({
                        base64Image: item.base64Image,
                        name: item.name
                    })),
                    // item_image_obj: [{
                    //         name: '',
                    //         base64Image: '',
                    //     }

                    // ],
                    item_has_license: procureObj.hasLicense,
                    item_license_obj: {
                        id: procureObj ?.productLicenseObj ?.id ?? null,
                        customer_user_id: this.user.id,
                        product_item_id: procureObj ?.productLicenseObj ?.id ?? null,
                        license_type: procureObj ?.productLicenseObj ?.license_type ?? "",
                        period: procureObj ?.productLicenseObj ?.period ?? "",
                        license_key: procureObj ?.productLicenseObj ?.license_key ?? "",
                    }

                }
                this.details.cart_data[parentIndex].product_items[childIndex] = {
                    ...this.details.cart_data[parentIndex].product_items[childIndex],
                    ...productItem
                }

                let selectedButton = document.querySelector(`#item-${this.selectedProductIndex}-${this.selectedProductItemIndex}`);
                selectedButton.classList.remove('text-warning');
                selectedButton.classList.add('text-success');
                selectedButton.innerHTML = "Update Details<i\n" + "class='bx bx-check-circle'></i>";
            }
            if (status === 2) {
                // procureObj data is saved product item data
                // for admin process
                this.details.cart_data[parentIndex].product_items[childIndex] = {
                    id: procureObj.productItemObj.id,
                    serial_number: procureObj.productItemObj.serial_number,
                    isAdminProcessed: true,
                    productLicense: procureObj.hasLicense === true ? procureObj.productLicenseObj : null
                };

            }
            if (status === 3) {
                console.log(procureObj, "declined");
                // procureObj data is empty obj {}
                this.details.cart_data[parentIndex].product_items[childIndex].procureProduct = procureObj;
            }
        },

        async getVendorList() {
            const response = await this.getVendors(this.getVendorsParams)
            if (response && response.message && response.status !== 200) {
                this.showToastMessage(response);
            }

        },

        async removeCartItem(cartListIndex, productPriceId) {
            /*let canRemoved = await this.canRemovedCartItem(cartListIndex);
            console.log(canRemoved);*/

            let countCartItem = this.details.cart_data.length;
            console.log(countCartItem, productPriceId);
            if (countCartItem > 1) {
                let existItem = this.productItemsID[productPriceId]
                existItem !== undefined ? delete this.productItemsID[productPriceId] : null;

                this.details.cart_data.splice(cartListIndex, 1);
                this.getTotal();
            }
        },

        async canRemovedCartItem(itemIndex) {
            let removeItemSubTotal = this.details.cart_data[itemIndex].product_price.subTotal * 100;
            console.log(`removed sub total amount${removeItemSubTotal}`)
            let tempGrandTotal = this.orderInfo.grand_total - removeItemSubTotal;
            console.log(`temporary grand total amount${tempGrandTotal}`)
            let paidAmount = this.paymentInfo.paid_amount;
            console.log(`paid  amount${paidAmount}`)
            if (!(paidAmount < tempGrandTotal)) {
                console.log('if  condition match');
                return await this.$refs.confirmDialogue.show({
                    title: 'Warning',
                    message: 'your removed item price less then paid amount. Do you proceed',
                    okButton: 'Yes',
                }).then((response) => {
                    return response === true;
                });
            }
        },

        async addProductPriceInCart(cardDataArray, obj) {
            const {
                productId,
                productPriceId,
                amount
            } = obj
            let selectedProductWithPrices = this.products.find((product) => product.id === productId);
            let selectedPrice = selectedProductWithPrices.productPrices.find((productPrice) => productPrice.id === productPriceId)
            //find product price ID exist on cart list
            let productPriceIndex = cardDataArray.findIndex((singleItem) => singleItem.productPrice.id === productPriceId)
            if (productPriceIndex === -1) {
                let cartObj = {
                    productId: selectedProductWithPrices.id,
                    name: selectedProductWithPrices.name,
                    code: selectedProductWithPrices.code,
                    product_price: {
                        id: selectedPrice.id,
                        condition_name: selectedPrice.condition,
                        condition_value: this.conditionOptions.find((option) => option.name.toLowerCase() === selectedPrice.condition.toLowerCase()).value,
                        amount: amount * 100, // converted amount in cent
                        subTotal: amount * 100, // converted amount in cent
                        quantity: 1,
                    }
                }
                cardDataArray.push(cartObj);
                this.showToastMessage({
                    type: 'success',
                    message: `successfully ${cartObj.name} with ${cartObj.product_price.condition_name} type added in cart`
                });
            } else {
                let existProductInfoOnList = cardDataArray[productPriceIndex];
                this.showToastMessage({
                    type: 'warning',
                    message: `Already ${existProductInfoOnList.name} with ${existProductInfoOnList.product_price.condition_name} type added in cart`
                });
            }
            await this.getTotal()

            // console.log(`${productId}--${productPriceId}--${amount}`);
        },

        async getEmitData(data) {
            await this.addProductPriceInCart(this.details.cart_data, data);
        },

        async showProductList() {
            this.getProductsParams.with_product_prices_state = this.user.address.state.toLowerCase() ?? '';
            this.getProductsParams.where_has_product_price_state_and_status[0] = this.user.address.state.toLowerCase() ?? '';
            this.getProductsParams.where_has_product_price_state_and_status[1] = 1;
            await this.getProductList(this.getProductsParams);
            this.products = this.$store.getters['appProducts/products'];
            document.querySelector('[data-target="#productListModal"]').click();
        },

        async getProductList(paramObj) {
            await this.getProducts(paramObj)
                .then((response) => {
                    this.isLoading = !!(response && response.message && response.status !== 200);
                });
        },

        isEmptyObject(value) {
            return Object.keys(value).length === 0 && value.constructor === Object;
        },

        async getSettingList(settingParams) {
            await this.getSettings(settingParams);
        },

        getTotal() {
            this.orderInfo.subtotal_amount = this.details.cart_data.reduce((acc, cur) => acc + parseInt(cur.product_price.sub_total), 0);
            this.orderInfo.gst_charge.amount = parseInt(((this.orderInfo.subtotal_amount - this.orderInfo.total_discount_amount) * this.gstRate) / 100);
            this.orderInfo.grand_total = (this.orderInfo.subtotal_amount +
                (this.orderInfo.gst_charge.applied_status === true ? this.orderInfo.gst_charge.amount : 0) +
                (this.orderInfo.order_delivery_charge.applied_status === true ? this.orderInfo.order_delivery_charge.amount : 0)) - this.orderInfo.total_discount_amount ?? 0;
        },

        increaseQuantity(cartListIndex) {
            this.details.cart_data[cartListIndex].product_items.push({
                is_admin_process: false,
                procureProduct: {}
            });

            this.details.cart_data[cartListIndex].product_price.quantity++;
            this.details.cart_data[cartListIndex].product_price.sub_total = this.details.cart_data[cartListIndex].product_price.amount * this.details.cart_data[cartListIndex].product_price.quantity;
            this.getTotal();
        },

        decreaseQuantity(cartListIndex) {

            if (this.details.cart_data[cartListIndex].product_price.quantity > 1) {
                let lastProductItem = this.details.cart_data[cartListIndex].product_items[this.details.cart_data[cartListIndex].product_items.length - 1]
                if (lastProductItem.is_admin_process === false) {
                    this.details.cart_data[cartListIndex].product_items.pop();
                    this.details.cart_data[cartListIndex].product_price.quantity--
                    this.details.cart_data[cartListIndex].product_price.sub_total = this.details.cart_data[cartListIndex].product_price.sub_total - this.details.cart_data[cartListIndex].product_price.amount;
                }
            } else {
                this.details.cart_data[cartListIndex].product_price.quantity = 1
            }
            this.getTotal()
        },
        /**
         * @description 1 for data & time 2 for date only 3 for time
         * @param currentDate
         * @param status
         */
        getCurrentDateTime(currentDate = new Date(), status) {
            let validDate = currentDate instanceof Date && !isNaN(currentDate);
            let today = validDate ? new Date(currentDate) : new Date();
            let date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
            let time = ('0' + today.getHours()).slice(-2) + ":" + ('0' + today.getMinutes()).slice(-2) + ":" + ('0' + today.getSeconds()).slice(-2);
            if (!status)
                return date + ' ' + time;
            if (status === 1)
                return date
            if (status === 2)
                return time
        },

        async getSingleOrder(id) {
            this.loader(true);
            let paramObj = {
                id: id,
                params: this.getOrderParams,
            };
            await this.getOrder(paramObj).then(async (response) => {
                this.loader(false);
                if (response.status === 200) {
                    this.orderReference = this.order.order_no;
                    this.orderID = this.order.id;
                    this.deliveryDate = this.order.delivery_date;
                    this.orderDate = this.order.order_date;
                    this.orderCreatedAt = this.order.created_at;
                    this.orderStatus = this.order.status;

                    this.user.id = this.order.user && this.order.user.id ? this.order.user.id : '';
                    this.user.firstName = this.order.user && this.order.user.first_name ? this.order.user.first_name : '';
                    this.user.lastName = this.order.user && this.order.user.last_name ? this.order.user.last_name : '';
                    this.user.email = this.order.user && this.order.user.email ? this.order.user.email : 'Not provided';
                    this.user.phoneNumber = this.order.user && this.order.user.phone_number ? this.order.user.phone_number : 'Not provided';
                    this.user.dateOfBirth = this.order.user && this.order.user.date_of_birth ? this.order.user.date_of_birth : '';
                    this.user.avatar = this.order.user && this.order.user.avatar ? this.order.user.avatar : '';
                    this.user.address = this.order.user && this.order.user.customer && this.order.user.customer.address ? this.order.user.customer.address : '';
                    this.orderInfo = JSON.parse(this.order.details);
                    // console.log(this.orderInfo);
                    this.details = (({
                        cart_data
                    }) => ({
                        cart_data
                    }))(this.orderInfo);
                    // generate procureProduct obj
                    this.details.cart_data.forEach((item) => {
                        let itemQuantity = item.product_price.quantity;

                        if (!item.product_items) {
                            item.product_items = [];
                            for (let index = 0; index < itemQuantity; index++) {
                                item.product_items.push({
                                    is_admin_process: false,
                                    procureProduct: {}
                                })
                            }
                        } else {
                            let length = Math.abs(itemQuantity - item.product_items.length);
                            for (let index = 0; index < length; index++) {
                                item.product_items.push({
                                    is_admin_process: false,
                                    procureProduct: {}
                                })
                            }

                            item.product_items.forEach(async(productItem) => {
                                if (productItem.is_admin_process === false && productItem.is_procure_item === true) {

                                    let procureProduct = {
                                        // item_image_obj
                                        productImageObj: await JSON.parse(JSON.stringify(productItem.item_image_obj)),
                                        productItemObj: {
                                            barcode: productItem ?.item_obj ?.barcode ?? '',
                                            condition: productItem ?.item_obj ?.condition ?? '',
                                            manufacturing_code: productItem ?.item_obj ?.condition ?? '',
                                            model_number: productItem ?.item_obj ?.model_number ?? '',
                                            product_id: productItem ?.item_obj ?.product_id ?? '',
                                            purchase_cost: productItem ?.item_obj ?.purchase_cost ?? '',
                                            qr_code: productItem ?.item_obj ?.qr_code ?? '',
                                            serial_number: productItem ?.item_obj ?.serial_number ?? '',
                                            sku: productItem ?.item_obj ?.sku ?? '',
                                            state: productItem ?.item_obj ?.state ?? '',
                                            vendor_id: productItem ?.item_obj ?.vendor_id ?? null,
                                            warehouse_id: productItem ?.item_obj ?.warehouse_id ?? null,
                                        },
                                        productLicenseObj: {
                                            // item_license_obj
                                            customer_id: productItem ?.item_license_obj ?.customer_user_id ?? null,
                                            license_key: productItem ?.item_license_obj ?.license_key ?? "",
                                            license_type: productItem ?.item_license_obj ?.license_type ?? "",
                                            period: productItem ?.item_license_obj ?.period ?? "",
                                            product_item_id: productItem ?.item_license_obj ?.product_item_id ?? null,
                                        },
                                        // item_has_license
                                        hasLicense: productItem ?.item_has_license ?? false,
                                        isAdminProcessed: productItem ?.is_admin_process ?? false,
                                        isProcureItem: productItem ?.is_procure_item ?? false,
                                    };
                                    productItem.procureProduct = procureProduct;

                                }
                            })
                        }

                    });
                    console.log(this.order.orderNotes.length, 'order notes');
                    this.orderNote = this.order.orderNotes.length !== 0 ? this.order.orderNotes.map((note) => {
                        if (note.user_type === 'Customer') {
                            return {
                                id: note.id,
                                description: note.description
                            };
                        }
                    })[0] : {
                        id: null,
                        description: ''
                    };
                    this.$refs.notes.setText(this.orderNote.description);

                }

                if (response && response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
            });
        },

        async getAllPayment(paramObj) {
            this.loader(true);

            await this.getPayments(paramObj).then(async (response) => {
                this.loader(false);
                if (response.status === 200) {
                    this.paymentID = this.payments ?. [0] ?.id ?? null;
                    // this.paymentType = this.payment.type;
                    this.paymentInfo.paid_amount = this.getPaymentsSum(this.payments);
                    //console.log(this.paymentInfo.paid_amount)
                    // how many times of payment type occur in this order {'card':2, 'cash':1}
                    this.paymentMethods = this.payments.map((payment) => (payment.type)).reduce((accumulator, value) => {
                        return {
                            ...accumulator,
                            [value]: (accumulator[value] || 0) + 1
                        };
                    }, {});
                    // calculate total paid amount without card
                    this.alreadyPaidAmount = this.payments.filter((payment) => payment.type !== 'Card')
                        .map((withoutCard) => withoutCard.total_transaction)
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

                    this.cardPaymentArray = this.payments.filter((payment) => payment.type === 'Card')
                        .map((filteredCardPayment) => filteredCardPayment.cardPayment ?? []);

                    this.orderPaymentArray = this.payments.map((payment) => {
                        return {
                            order_id: this.orderID,
                            payment_id: payment.id,
                            transaction_date_time: payment.created_at
                        }
                    });

                }

                if (response && response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
            });
        },

        getPaymentsSum(paymentArray) {
            return Array.isArray(paymentArray) ?
                paymentArray.reduce((acc, cur) => acc + parseInt(cur.total_transaction), 0) : 0

        },

        addSerialOnList(listId, serialNo) {
            let ul = document.getElementById(listId),
                li = document.createElement("li");
            /*let children = ul.children.length + 1
            li.setAttribute("id", "element"+children)*/
            li.appendChild(document.createTextNode(serialNo));
            ul.appendChild(li)
        },

        async getSerial(productId, productName, productConditionType, quantity, productPriceId) {
            // pass to modal component
            this.selectedProduct = {
                id: productId,
                name: productName
            };
            this.selectedProductPriceId = productPriceId;
            this.selectedState = this.user.address.state;
            this.selectedCondition = productConditionType;
            this.selectedListId = `list-${productId}-${productConditionType}`
            this.cartItemCount = quantity;
            console.log(`${productId}-${productConditionType}-${quantity}`)
            await this.getProductItemList(productId, productConditionType);

        },

        async getProductItemList(productId, productConditionType) {
            const paramObj = {
                product_id: productId,
                condition: productConditionType,
                where_doesnt_have_product_item_transaction: 1,
                with_relation: ['productLicense']
            }
            await this.getProductItems(paramObj).then(async (response) => {
                this.loader(false);

                if (response && response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
            });
        },

        selectedItemId(productItemIds) {
            console.log(productItemIds)
            this.productItemsID[this.selectedProductPriceId] = productItemIds;
            let list = document.getElementById(this.selectedListId)
            document.querySelector('[data-dismiss="modal"]').click();
            list.innerHTML = '';
            this.productItems.forEach(item => {
                if (productItemIds.includes(item.id)) {
                    this.addSerialOnList(this.selectedListId, item.serial_number);
                    // product license data added
                    let alreadyExistProductLicense = this.productLicenseData.findIndex((singleItem) => singleItem.productItemId === item.id);
                    if (alreadyExistProductLicense === -1 && item.productLicense !== null) {
                        let productLicense = {
                            productItemId: item.id,
                            productLicense: item.productLicense
                        };
                        this.productLicenseData.push(productLicense);
                    }

                }
            });
            this.checkOrderProcessHandler();
        },

        async generateOrderChargesData(orderObject, chargesArray) {
            const orderChargesData = [];
            chargesArray.forEach(item => {
                if (item in orderObject) {
                    orderChargesData.push(orderObject + '.' + item + '.amount')
                }
            })
            console.log(orderChargesData)
        },
        // step one
        async createOrderCharges() {
            const charges = [];
            if (this.orderInfo.gst_charge.applied_status) {
                charges.push({
                    "amount": this.orderInfo.gst_charge.amount,
                    "type": 4,
                    "name": "GST Charge"
                });
            }
            if (this.orderInfo.order_delivery_charge.applied_status) {
                charges.push({
                    "amount": this.orderInfo.order_delivery_charge.amount,
                    "type": 3,
                    "name": "Delivery Charge"
                });
            }
            if (this.orderInfo.applied_discount.applied_status) {
                charges.push({
                    "amount": this.orderInfo.applied_discount.amount,
                    "type": 2,
                    "name": "Applied Discount"
                });
            }
            if (this.orderInfo.coupon_discount.applied_status) {
                charges.push({
                    "amount": this.orderInfo.coupon_discount.amount,
                    "type": 2,
                    "name": "Coupon Discount"
                });
            }
            if (this.orderInfo.credited_payment_discount.applied_status) {
                charges.push({
                    "amount": this.orderInfo.credited_payment_discount.amount,
                    "type": 2,
                    "name": "Credited Discount"
                });
            }

            if (this.orderInfo.subtotal_amount) {
                charges.push({
                    "amount": this.orderInfo.subtotal_amount,
                    "type": 0,
                    "name": "Item Price"
                });
            }
            const data = {
                order_id: this.orderID,
                charges: charges
            };
            this.loader(true);
            const response = await this.postOrderChargeStoreList(data);
            console.log(response, "order charges");
            if (response.status === 200 || response.status === 201) {
                return true;
            } else {
                this.loader(false);
                return false;
            }

        },
        // step two
        async createOrderCreator() {
            const data = {
                user_id: this.user.id,
                order_id: this.orderID,
                panel: 1
            };
            this.loader(true);
            const response = await this.postOrderCreator(data);
            console.log(response, "order creators");
            if (response.status === 200 || response.status === 201) {
                return true;
            } else {
                this.loader(false);
                return false
            }

        },
        // step three
        async createPaymentCapture(cardObj) {
            let data = {
                currency: 'AUD',
                reference: 'order-admin-panel',
                amount: cardObj.amount,
                description: 'this is payment test description',
                authorization_id: cardObj.payment_gateway_id,
            }
            this.loader(true);
            return await this.paymentCapture(data).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    console.log(response, "card payment capture");
                    this.loader(false);
                    return true
                } else {
                    this.loader(false);
                }
                if (response.message) {
                    this.showToastMessage(response);
                }
                return false;
            });

        },
        // step four
        async createOrderPaymentProcess() {
            try {
                for (const orderPayment of this.orderPaymentArray) {
                    await this.createOrderPayment(orderPayment);
                }
                return true;
            } catch (e) {
                throw new Error(`Failed in order payment process,${e.message}`);
            }

        },

        async createOrderPayment(paymentObj) {
            const data = {
                payment_id: paymentObj.payment_id,
                order_id: paymentObj.order_id,
                transaction_date_time: paymentObj.transaction_date_time,
            };
            this.loader(true);
            const response = await this.postOrderPayment(data);
            if (response.status === 200 || response.status === 201) {
                console.log(response, "order payment");
                this.loader(false);
                return true;
            } else {
                this.loader(false);
                return false
            }

        },
        // step five
        async createOrderProductItems() {
            const data = {
                product_items: this.addedProductItems,
                order_id: this.orderID,
            };
            this.loader(true);
            const response = await this.postOrderProductItemStoreList(data);
            console.log(response, "order product items");
            if (response.status === 200 || response.status === 201) {
                this.loader(false);
                return true;
            } else {
                this.loader(false);
                return false
            }

        },

        async createAuthorizationReversed(cardObj) {
            const data = {
                authorization_id: cardObj.payment_gateway_id,
            };
            this.loader(true);
            const response = await this.postAuthorizationReversed(data);
            console.log(response, "authorization reversed");
            if (response.status === 200 || response.status === 201) {
                this.loader(false);
                return true;
            } else {
                this.loader(false);
                return false
            }

        },

        async updateCardPayment(cardObj) {
            let dataObj = {
                id: cardObj.id,
                data: {
                    amount: cardObj.amount,
                    status: cardObj.payment_status // 1; for paid, 2: for reversed
                }
            }
            this.loader(true);
            const response = await this.putCardPayment(dataObj);
            if (response.status === 200) {
                console.log(response, "after update card payment status");
                this.loader(false);
                return true;
            } else {
                this.loader(false);
            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false;
        },

        async updateOrderStatus(finalData) {

            let dataObj = {
                id: this.orderID,
                data: {
                    status: 0,
                    details: JSON.stringify(finalData)
                }
            }
            if (this.delivery_date !== '') {
                dataObj.data.delivery_date = this.delivery_date;
            }
            this.loader(true);
            const response = await this.putOrder(dataObj);
            if (response.status === 200) {
                console.log(response, "after update order status");
                this.loader(false);
                return true
            } else {
                this.loader(false);
            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false
        },

        checkOrderProcessHandler() {
            let itemPrice = null;
            this.addedProductItems = [];
            this.addedProductItemPrices = [];
            this.addedProductItemLicense = [];
            let cartItemCount = this.details.cart_data.reduce((acc, cur) => acc + Number(cur.product_price.quantity), 0);
            let count = 0;

            for (const pro in this.productItemsID) {
                let serialCount = this.productItemsID[pro].length;
                let itemsObj = this.productItemsID[pro].map((item) => {
                    return {
                        'product_item_id': item,
                        'action': 2, // purchased by
                        'user_id': this.user.id,
                    }
                });
                let itemIndex = this.details.cart_data.findIndex((item) => {
                    return item.product_price.id == pro;
                }, pro);

                if (itemIndex !== -1) {
                    itemPrice = this.details.cart_data[itemIndex].product_price.amount
                }
                let itemsPriceObj = this.productItemsID[pro].map((item) => {
                    return {
                        'sold_to': this.user.id,
                        'sold_by': this.authUser.id,
                        'product_item_id': item,
                        'sell_price': itemPrice
                    }
                }, itemPrice);

                // product license
                let itemLicenseObj = this.productItemsID[pro].map((item) => {
                    let filterLicense = this.productLicenseData.filter((single) => single.productItemId === item) ?. [0] ?.productLicense ?? null;
                    if (filterLicense !== null) {
                        let currentDateTime = this.getCurrentDateTime();
                        return {
                            'id': filterLicense.id,
                            'start_at': currentDateTime,
                            'end_at': this.getCurrentDateTime(this.getFutureDateTime(filterLicense.period))
                        }
                    }
                }).filter((itemLicense) => Boolean(itemLicense)); // check null & undefined
                //console.log(itemLicenseObj);

                Array.prototype.push.apply(this.addedProductItems, itemsObj);
                Array.prototype.push.apply(this.addedProductItemPrices, itemsPriceObj);
                Array.prototype.push.apply(this.addedProductItemLicense, itemLicenseObj);
                count = count + serialCount
            }

            return cartItemCount === count;

        },

        getFutureDateTime(timeString) {
            const timeStrings = {
                "+7 days": new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)),
                "+15 days": new Date(new Date().getTime() + (15 * 24 * 60 * 60 * 1000)),
                "+30 days": new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)),
                "+3 months": new Date(new Date().getTime() + (90 * 24 * 60 * 60 * 1000)),
                "+6 months": new Date(new Date().getTime() + (180 * 24 * 60 * 60 * 1000)),
                "+1 year": new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000)),
                "+2 years": new Date(new Date().getTime() + (730 * 24 * 60 * 60 * 1000)),
                "+3 years": new Date(new Date().getTime() + (1095 * 24 * 60 * 60 * 1000)),
            };

            return timeStrings[timeString] ?? null;
        },

        async createPaymentTypeProcess() {
            let status = true;
            let remain = this.orderInfo.grand_total - this.paymentInfo.paid_amount
            // nothing to do just if card payment
            if (remain >= 0) {
                console.log(remain, 'remain 0 or more');
                try {
                    for (const cardPayment of this.cardPaymentArray) {
                        // update card capture call
                        const isPaymentCaptureCreated = await this.createPaymentCapture(cardPayment);
                        cardPayment.payment_status = 1; // 1: for paid
                        if (isPaymentCaptureCreated) {
                            await this.updateCardPayment(cardPayment);
                        } else {
                            status = false
                            break;
                        }
                    }
                    return status;
                } catch (err) {
                    throw new Error(`Failed in card payment process,${err.message}`);
                }
            }
            // remain is  less than 0 then refund occur.
            if (remain < 0) {
                console.log(remain, 'remain less than 0');
                let remainingAmount = (this.orderInfo.grand_total - this.alreadyPaidAmount);
                // if remaining amount is 0 then all card payment reversed
                if (remainingAmount === 0) {
                    for (const cardPayment of this.cardPaymentArray) {
                        // reverse all card amount
                        const isPaymentAuthorizedReversed = await this.createAuthorizationReversed(cardPayment);
                        cardPayment.payment_status = 2 // 2: for reversed
                        if (isPaymentAuthorizedReversed) {
                            await this.updateCardPayment(cardPayment);
                        }
                    }
                    return status;
                }
                // if remaining amount is negative then all card payment reversed and cash refund
                if (remainingAmount < 0) {
                    for (const cardPayment of this.cardPaymentArray) {
                        // reverse all card amount
                        const isPaymentAuthorizedReversed = await this.createAuthorizationReversed(cardPayment);
                        cardPayment.payment_status = 2 // 2: for reversed
                        if (isPaymentAuthorizedReversed) {
                            await this.updateCardPayment(cardPayment);
                        }
                    }
                    // manual cash refund create
                    await this.createSingleRefund(Math.abs(remainingAmount));
                    return status;

                }
                // if remaining amount is positive then all card payment processed or reversed
                if (remainingAmount > 0) {
                    if (this.cardPaymentArray.length > 0) {
                        for (const cardPayment of this.cardPaymentArray) {

                            if (remainingAmount > 0 && cardPayment.amount > 0) {
                                let captureAmount = remainingAmount <= cardPayment.amount ? remainingAmount : cardPayment.amount;
                                cardPayment.amount = captureAmount;
                                // update card capture call
                                const isPaymentCaptureCreated = await this.createPaymentCapture(cardPayment);
                                cardPayment.payment_status = 1; // 1: for paid
                                if (isPaymentCaptureCreated) {
                                    await this.updateCardPayment(cardPayment);
                                } else {
                                    status = false
                                    break;
                                }
                                remainingAmount -= captureAmount;
                            } else {
                                // reverse all card amount
                                const isPaymentAuthorizedReversed = await this.createAuthorizationReversed(cardPayment);
                                cardPayment.payment_status = 2 // 2: for reversed
                                if (isPaymentAuthorizedReversed) {
                                    await this.updateCardPayment(cardPayment);
                                } else {
                                    status = false
                                    break;
                                }
                                remainingAmount += cardPayment.amount;
                            }
                        }
                    }
                    return status;
                }
            }

        },

        async orderProcess() {

            // const countStatus = this.details.cart_data.map((singleCartItem) => {
            //     let quantity = singleCartItem.product_price.quantity;
            //     let itemCount = singleCartItem ?.product_items ?.length ?? 0;
            //     console.log(quantity, itemCount)
            //     return quantity === itemCount;
            // });

            this.orderInfo.is_order_procure_requested = true;
            this.orderInfo.is_order_procured = false;
            const finalData = JSON.parse(JSON.stringify(this.orderInfo));
            finalData.cart_data.forEach((cartTtem) => {
                if (cartTtem ?.product_items) {
                    cartTtem.product_items.forEach((productItem,index) => {
                        if (productItem?.procureProduct) {
                            delete productItem?.procureProduct;
                        }
                        if (Object.keys(productItem).length === 2 || Object.keys(productItem).length === 1) {
                            cartTtem.product_items.splice(index, 1);
                        }
                       
                    })
                    // cartTtem.product_items.map((product)=>);
                    // cartTtem.product_items = cartTtem.product_items.filter((singleProductItem) => (singleProductItem?.item_obj) &&  singleProductItem );
                }

            });

            // console.log(finalData, "final data ");

            //

            let orderStatus = await this.updateOrderStatus(finalData);
            if (orderStatus) {
                this.showToastMessage({
                    message: 'Order procure successfully, wait for admin approval',
                    type: 'success'
                });
                await this.$router.push({
                    name: 'appOrderList'
                });
            }

            //if order comes from customer  panel
            // if (this.orderInfo ?.creator ?.panel === 3 && this.delivery_date === '') {
            //     this.showToastMessage({
            //         message: 'please select delivery date',
            //         type: 'warning'
            //     });
            //     return;
            // }
            /* await this.$refs.confirmDialogue.show({
               title: 'Confirm',
               message: 'Are you sure you want to payment now',
               okButton: 'Yes',
             }).then((response) => {
               if (response === true) {
                 alert('You have successfully delete this page.')
               } else {
                 alert('You chose not to delete this page. Doing nothing now.')
               }
             });*/
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            return false;
            // eslint-disable-next-line no-unreachable
            const isProcessHandlerPassed = this.checkOrderProcessHandler()
            if (isProcessHandlerPassed) {
                const isPaymentTypeProcess = await this.createPaymentTypeProcess();
                if (isPaymentTypeProcess) {
                    const isOrderCreatorCreated = await this.createOrderCreator();
                    if (isOrderCreatorCreated) {
                        const isOrderPaymentCreated = await this.createOrderPaymentProcess();
                        if (isOrderPaymentCreated) {
                            const isOrderProductItemCreated = await this.createOrderProductItems();
                            if (isOrderProductItemCreated) {
                                const isOrderChargesCreated = await this.createOrderCharges();
                                if (isOrderChargesCreated) {
                                    const isProductItemTrackingStatus = await this.createProductItemTransaction();
                                    if (isProductItemTrackingStatus) {
                                        const isProductItemTransactionStatus = await this.createProductItemTrackings();
                                        if (isProductItemTransactionStatus) {
                                            const isOrderStatusUpdated = await this.updateOrderStatus();
                                            if (isOrderStatusUpdated) {
                                                await this.updateProductLicense();
                                                if (this.isOrderNoteChange) {
                                                    await this.updateOrderNote();
                                                }
                                                this.showToastMessage({
                                                    message: 'Order process successfully',
                                                    type: 'success'
                                                });
                                                await this.$router.push({
                                                    name: 'appOrderList'
                                                });

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                this.showToastMessage({
                    message: 'added Product Serial Item must be equal to total quantity',
                    type: 'warning'
                });
            }

        },

        async updateProductLicense() {
            if (this.addedProductItemLicense.length > 0) {
                const dataObj = {
                    user_id: this.user.id,
                    licenses: JSON.stringify(this.addedProductItemLicense),
                };
                this.loader(true);
                return await this.postProductLicenseUpdateList(dataObj).then((response) => {
                    console.log(response, "after product license");
                    if (response.status === 204) {
                        this.loader(false);
                        return true;
                    } else {
                        this.loader(false);
                        return false;
                    }
                });
            } else {
                return true;
            }

        },

        async createProductItemTrackings() {
            const dataObj = {
                product_items: JSON.stringify(this.addedProductItems)
            };
            this.loader(true);
            return await this.postProductItemTrackingStoreList(dataObj).then((response) => {
                console.log(response, "after product item tracking");
                if (response.status === 200 || response.status === 201) {
                    this.loader(false);
                    return true;
                } else {
                    this.loader(false);
                    return false;
                }
            });
        },

        async createProductItemTransaction() {
            const dataObj = {
                product_items: this.addedProductItemPrices,
            };
            this.loader(true);
            return await this.postProductItemTransactionStoreList(dataObj).then((response) => {
                console.log(response, "after product item transaction");
                if (response.status === 200 || response.status === 201) {
                    this.loader(false);
                    return true;
                } else {
                    this.loader(false);
                    return false;
                }
            });
        },

        async updateOrderNote() {
            let dataObj = {
                id: this.orderNote.id,
                data: {
                    description: this.description
                }
            }
            this.loader(true);
            const response = await this.putOrderNote(dataObj);
            if (response.status === 200) {
                console.log(response, "order note updated");
                this.loader(false);
                return true
            } else {
                this.loader(false);
            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false
        },

        async createSingleRefundNote(refundId) {

            const data = {
                user_id: this.authUser.id,
                refund_id: refundId,
                user_type: 0, // 0: system auto
                type: 0, // 0: for incorrect amount charge
                description: 'Refunds are being given for lowering the orders product amount'
            }

            return await this.postRefundNote(data).then((response) => {
                if (response.status === 201) {
                    return true;
                }

                if (response.message) {
                    this.showToastMessage(response);
                }
            });

        },

        async createSingleRefund(refundAmount) {
            let postRefundData = {
                processed_by: '',
                paid_to: this.authUser.id,
                reference: this.orderReference,
                payment_method: 0, // 0: for cash,
                payment_gateway: '',
                payment_gateway_id: '',
                reason: 0, // 0: for incorrect amount charge
                amount: refundAmount,
                status: 0, // 0: pending,
            }
            this.loader(true);
            return await this.postRefund(postRefundData).then(async (response) => {
                if (response.status === 201) {
                    let refundId = this.$store.getters['appRefunds/refund'].id;

                    await this.createSingleRefundNote(refundId);

                    return true;

                } else {
                    this.loader(false);
                }

                this.errors.refund = {
                    paid_to: response ?.errors ?.paid_to ?. [0] ?? '',
                    reference: response ?.errors ?.reference ?. [0] ?? '',
                    payment_method: response ?.errors ?.payment_method ?. [0] ?? '',
                    reason: response ?.errors ?.reason ?. [0] ?? '',
                    amount: response ?.errors ?.amount ?. [0] ?? '',
                }

                if (response.message) {
                    this.showToastMessage(response);
                }
                return false;

            });
        },
    },
    async mounted() {
        this.loader(true);
        const routeParams = await this.$route.params;
        await this.getSettingList(this.getSettingsParams);
        this.gstRate = this.$store.getters['appSettings/settingDefaultGstRate'].value;
        await this.getSingleOrder(routeParams.id);

        this.getPaymentParams.reference = this.orderReference;
        await this.getAllPayment(this.getPaymentParams);
        this.loader(false);
        // await this.generateOrderChargesData(this.orderInfo, this.preOrderChargesKey);
    },

    async beforeUnmount() {
        await this.resetOrder();
    },
}
</script>

<style scoped>
.btn-quantity {
    width: 50px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;

}

.quantity {
    width: 30px;
    text-align: center;

}

.quantity-section {
    border: 1px solid #DFE3E7;
    height: 30px;
}

/*.add-sn-btn {
  background-color: #2c6de9 !important;
  color: white;
  font-size: 18px;
  border: none;
  outline: none;
  padding: 2px 5px;
  cursor: pointer;
}*/
</style>
